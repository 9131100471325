import React, {useState} from "react";
import styled from "styled-components";
import { Element } from 'react-scroll'
import ScrollAnimation from 'react-animate-on-scroll'


import Header from '../components/header'
import Footer from '../components/footer'
import Card from '../components/common/Card'
import ContactSection from '../components/ContactSection'


export default () => {
	const [box1Hover, setBox1Hover] = useState(false);
	const [box2Hover, setBox2Hover] = useState(false);
	const [box3Hover, setBox3Hover] = useState(false);


  return(
    <Wrapper>
     	<Header/>
   		<Black>
   			<Section>
					<ScrollAnimation animateOnce={true} animateIn={"myAnimation"}>
					<Title>What we believe</Title>
   				<Text>We strongly believe in sustainability, technology and experience.</Text>
					</ScrollAnimation>
	   			<Card
	   				reversed={true}
	   				imgSrc="../../assets/camacgroup/img2.png"
	   				imgAlt="camacgroup img 2"
	   				title="Sustainability"
	   				text="Together with our partners we aim to drive disruptive and impactful change by embedding sustainability into everything we do. By helping you take a responsible and sustainable approach that contributes to a better world, we are working to shape the future of smart cities."
	   			/>
	   			<Card
	   				reversed={false}
	   				imgSrc="../../assets/camacgroup/img3.png"
	   				imgAlt="camacgroup img 3"
	   				title="Technology "
	   				text="Smart & intelligent spaces, buildings and cities give its owners and users more and better information about their environment and how it's being used. We endeavor to achieve the highest global standards."
	   			/>
	   			<Card
	   				reversed={true}
	   				imgSrc="../../assets/camacgroup/img4.png"
	   				imgAlt="camacgroup img 4"
	   				title="Experience "
	   				text="We want to grow and do better for our clients and ourselves. That's why we are committed to user experience and to always seek new and innovative ways to make it completely immersive."
	   			/>
	   		</Section>
	   		<Line />
	   		<Section>
					<ScrollAnimation animateOnce={true} animateIn={"myAnimation"}>
						<Title alpina={true}>Camac in numbers</Title>
					</ScrollAnimation>
					<ScrollAnimation animateOnce={true} animateIn={"myAnimation"}>
	   				<Flex>
							<SmallCard>
								<Number>32</Number>
								<Text>professionals from various fields and backgrounds </Text>
							</SmallCard>
							<SmallCard>
								<Number>3</Number>
								<Text>currently major live ventures globally</Text>
							</SmallCard>
							<SmallCard>
								<Number>1</Number>
								<Text>goal - 100 % sustainability</Text>
							</SmallCard>
	   				</Flex>
					</ScrollAnimation>
	   		</Section>
	   		<Line />
	   		<Section>
					<ScrollAnimation animateOnce={true} animateIn={"myAnimation"}>
						<Title>What we do?</Title>
						<Text>In short, we continuously questions status quo.</Text>
					</ScrollAnimation>
	   			<Card 
	   				reversed={true}
	   				imgSrc="../../assets/camacgroup/img5.png"
	   				imgAlt="camacgroup img 5"
	   				title="Mission"
	   				text="Our mission is to enable businesses to  create exceptional experiences, by implementing smart and sustainable technologies."
	   			/>
	   			<Card 
	   				reversed={false}
	   				imgSrc="../../assets/camacgroup/img6.png"
	   				imgAlt="camacgroup img 6"
	   				title="Vision"
	   				text="We want to make CAMAC a world-leading, sustainable services firm by creating spaces, buildings and cities where people can have rewarding opportunities and amazing spaces around the globe to achieve their ambitions. In doing so, we are building a better tomorrow for our clients, our people and our communities."
	   			/>
	   			<Card
	   				reversed={true}
	   				imgSrc="../../assets/camacgroup/img7.png"
	   				imgAlt="camacgroup img 7"
	   				title="All you need is Camac"
	   				text="We are an ecosystem of aficionados that came together with one goal – to bridge the gap between ideas and execution, to change the world."
	   			/>
	   		</Section>
   		</Black>
   		<White>
   			<Section>
					<ScrollAnimation animateOnce={true} animateIn={"myAnimation"}>
						<Title3 white={true}>You are in good company</Title3>
					</ScrollAnimation>
					<ScrollAnimation animateOnce={true} animateIn={"myAnimation"}>
						<LogosWrapp>
							<Logos src="../../assets/camacgroup/logos.png" alt="camacgroup logos" />
						</LogosWrapp>
					</ScrollAnimation>
					<ScrollAnimation animateOnce={true} animateIn={"myAnimation"}>
   					<Text white={true}>All companies are part of our ecosystem and we have collaborated and we continue to do so. </Text>
					</ScrollAnimation>
   			</Section>
   		</White>
   		<Black>
   			<Section>
					<ScrollAnimation animateOnce={true} animateIn={"myAnimation"}>
						<Title3>Say Hello!</Title3>
						<Email href="mailto:hello@camacgroup.co">hello@camacgroup.co</Email>
						<Text>Our Camac experts love to make your life easier. Just get in touch.</Text>
					</ScrollAnimation>
   			</Section>
   		</Black>
				<Line/>
			<Black>
				<Section>
					<ScrollAnimation animateOnce={true} animateIn={"myAnimation"}>
						<Title>The Camac family</Title>
						<Text>This is only a beginning, our family is constantly growing and expanding. </Text>
					</ScrollAnimation>
					<BoxWrapper>
						<ScrollAnimation animateOnce={true} animateIn={"myAnimation"}>
							<BoxInnerWrapper>
								<Box
									href={"https://camacspace.com"}
									target={"_blank"}
									onMouseEnter={() => setBox1Hover(true)}
									onMouseLeave={() => setBox1Hover(false)}
								>{box1Hover === true ? 'Workplace Experience' : 'Space'}</Box>
								<Box
									onMouseEnter={() => setBox2Hover(true)}
									onMouseLeave={() => setBox2Hover(false)}
								>{box2Hover === true ? 'DATA Analytics' : 'CruX'}</Box>
								<Box
									onMouseEnter={() => setBox3Hover(true)}
									onMouseLeave={() => setBox3Hover(false)}
								>{box3Hover === true ? 'Sustainability Advisory' : 'CamacX'}</Box>
							</BoxInnerWrapper>
						</ScrollAnimation>
					</BoxWrapper>
				</Section>
			</Black>
   		<White>
   			<Section>
					<ScrollAnimation animateOnce={true} animateIn={"myAnimation"}>
						<Title white={true}>Yes, we help!</Title>
						<Text white={true}>If we want change and we want things to be better, we must look inward and be the ones taking action.</Text>
					</ScrollAnimation>
   				<Card
   					white={true}
	   				reversed={true}
	   				imgSrc="../../assets/camacgroup/img8.png"
	   				imgAlt="camacgroup img 7"
	   				title="We donate 10% of our profit"
	   				text="10% of all our profit will go to charities and/or direct donations nominated by our employees and shareholders. Our sole focus is education, with emphasis on children and young people, as they hold the responsibility of the tomorrow’s society. We think it is important to give back to society and like to think that our donations however small can help to make a difference somewhere in the world. "
	   			/>
					<ScrollAnimation animateOnce={true} animateIn={"myAnimation"}>
	   				<Title3 white={true}>Become part of our story</Title3>
					</ScrollAnimation>
					<ScrollAnimation animateOnce={true} animateIn={"myAnimation"}>
						<LogosSmallWrap>
							<LogosSmall src="../../assets/camacgroup/img9.png" alt="camacgroup logos" />
						</LogosSmallWrap>
					</ScrollAnimation>
					<ScrollAnimation animateOnce={true} animateIn={"myAnimation"}>
   					<Text white={true}>All companies are part of our ecosystem and we have <br />collaborated and we continue to do soo. </Text>
					</ScrollAnimation>
   			</Section>
   		</White>
      <Element name="contact" id="contact">
     		<Gray>
     			<Section>
     				<ContactSection />
     			</Section>
     		</Gray>
      </Element>
    	<Footer/>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`

const Black = styled.div`
  width: 100%;
  margin: 0 auto;
  background: #000000;
`

const White = styled.div`
  width: 100%;
  margin: 0 auto;
  background: #fff;
`

const Gray = styled.div`
  width: 100%;
  margin: 0 auto;
  background: #D8D9DA;
`

const BoxWrapper = styled.div`
	display: flex;
	justify-content: center;
`

const BoxInnerWrapper = styled.div`
  box-sizing: border-box;
	width: 100%;
	margin: 206px 0 54px 0;
	display: inline-flex;
	flex-wrap: wrap;
	max-width: 1277px;
	@media (max-width: 983px) {
		justify-content: center;
	}
	@media (max-width: 500px) {
		margin: 50px 0 0 0;
	}
`

const Box = styled.a`
	font-family: GTAlpinaTrial;
	flex-basis: calc(50% - 32px);
	display: flex;
	margin: 16px;
	align-items: center;
	justify-content: center;
	max-width: 606px;
	min-width: 420px;
	min-height: 152px;
	box-sizing: border-box;
	border: 1px solid #ffffff;
	color: #ffffff;
	height: 152px;
	font-weight: bold;
	font-size: 72px;
	line-height: 28px;
	flex-shrink: 2;
	text-decoration: none;
	cursor: pointer;
	:hover {
		color: #000000;
		background: #ffffff;
		font-size: 40px;
		line-height: 47px;
		font-family: StudioFeixenSansVariable;
		font-weight: normal;
	}
	@media (max-width: 500px) {
		min-height: 80px;
		height: 80px;
		min-width: 287px;
		width: 100%;
		font-size: 32px;
		line-height: 28px;
		font-family: StudioFeixenSansVariable;
	}
`

const Section = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  width: calc(100% - 80px);
  padding-top: 120px;
  padding-bottom: 120px;
  @media (max-width: 600px) {
    width: calc(100% - 40px);
    padding-top: 60px;
  	padding-bottom: 60px;
  }
`

const Line = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  width: calc(100% - 80px);
  background: #fff;
  height: 2px;
`


const Title = styled.h2`
  font-family: ${props => props.alpina ? "GTAlpinaTrial" : "StudioFeixenSansVariable"};
  font-size: 80px;
  color: ${props => props.white ? "#000" : "#fff"};
  font-weight:500;
  padding-bottom:24px;
  text-align:center;
  @media (max-width: 600px) {
    font-size: 40px;
  }
`

const Text = styled.div`
  font-size: 24px;
	font-weight: 500;
  color: ${props => props.white ? "#000" : "#fff"};
  text-align:center;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`

const Flex = styled.div`
 	display: flex;
 	justify-content: space-between;
 	padding-top: 120px;
 	padding-bottom: 120px;
  max-width: 1060px;
  margin: 0 auto;
 	@media (max-width: 1050px) {
    flex-direction:column;
    align-items:center;
  }
  @media (max-width: 600px) {
    padding-top: 0px;
 		padding-bottom: 30px;
  }
`

const SmallCard = styled.div`
 	max-width:320px;
 	@media (max-width: 1050px) {
    margin-top:24px;
  }
`

const Number = styled.div`
  font-family: GTAlpinaTrial;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #FFF;
  font-size:144px;
  // text-shadow: -2px 0 #fff, 0 2px #fff, 2px 0 #fff, 0 -2px #fff;
  text-align:center;
`

const Email = styled.a`
  font-family: GTAlpinaTrial;
	display:block;
	font-size:124px;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #FFF;
	// text-shadow: -2px 0 #fff, 0 2px #fff, 2px 0 #fff, 0 -2px #fff;
	text-align:center;
	margin-bottom:24px;
	color:#000;
	transition: 0.2s;
	text-decoration: none;
	:hover {
		color:#fff;
	};
	@media (max-width: 1350px) {
    font-size:84px;
  };
  @media (max-width: 950px) {
    font-size:54px;
  };
  @media (max-width: 600px) {
    font-size:30px;
  };
`

const LogosWrapp = styled.div`
  text-align: center;
`

const Logos = styled.img`
 	width:100%;
 	padding-top: 120px;
 	padding-bottom: 120px;
  max-width: 1060px;
 	@media (max-width: 600px) {
    padding-top: 6px;
 		padding-bottom: 30px;
  };
`

const LogosSmall = styled.img`
 	width:100%;
 	max-width:500px;
 	padding-top: 120px;
 	padding-bottom: 120px;
 	@media (max-width: 600px) {
    padding-top: 6px;
 		padding-bottom: 30px;
  };
`

const LogosSmallWrap = styled.div`
 	margin: 0 auto;
 	text-align: center;
`

const Title3 = styled.h3`
  font-family: GTAlpinaTrial; 
  font-size: 56px;
  color: ${props => props.white ? "#000" : "#fff"};
  font-weight:600;
  padding-bottom:24px;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 32px;
  }
`
