import React from 'react';
import styled from 'styled-components';
import BrowserRouter from 'react-router-dom/BrowserRouter';
import Routes from "./Routes";
// import Link from 'react-router-dom/Link';

const App = ({children}) => {
  return (
    <BrowserRouter>
      <Wrapper>
        <Routes />
         {/*<div style={{display:"none"}}>
          <Link to="/terms-and-conditions"></Link>
          <Link to="/privacy"></Link>
          <Link to="/"></Link>
          <Link to="/contact"></Link>
          <Link to="/thank-you"></Link>
          <Link to="/contact-thank-you"></Link>
        </div>*/}
      </Wrapper>
    </BrowserRouter>
  );
}

export default App;


const Wrapper = styled.main(props => ({

}));
