import styled from 'styled-components'
import React from "react";
import {Link} from "react-router-dom";


const Footer = (props) => {
  return (
    <Wrapper>
      <InnerWrapper>
        <InnerBodyWrapper>
          <ContentWrapper>
            <TitleWrapper>
              News
            </TitleWrapper>
            <form action="/thank-you" name="email" method="post" >
              <input type="hidden" name="form-name" value="email" />
              <MailWrapper>
                <Label>
                  <YourMailTextWrapper
                    type={'email'}
                    name={'email'}
                    placeholder={'Your Mail'}
                  />
                </Label>
                <Button type={'submit'}>
                  <ArrowImg
                    src={'../../../assets/images/arrow.svg'}
                    alt={'arrow'}
                  />
                </Button>
              </MailWrapper>
            </form>
            <TextWrapper1>
              YES I’d like to receive news and updates from Camac Space.
            </TextWrapper1>
            <Link to={"privacy"} onClick={()=>window.scrollTo(0,0)}>
              <PrivacyPolicyWrapper >
                Privacy policy
              </PrivacyPolicyWrapper>
            </Link>
          </ContentWrapper>
          <LocationsWrapper>
            <LocationWrapper>
              <Location>
                London
              </Location>
              <DetailsWrapper>
                <TextWrapper2>
                  Kemp house, 160 City Rd
                </TextWrapper2>
                <TextWrapper2>
                  London EC1V 2NX
                </TextWrapper2>
                <TextWrapper2>
                  +4420 8103 9321
                </TextWrapper2>
              </DetailsWrapper>
            </LocationWrapper>
          </LocationsWrapper>
        </InnerBodyWrapper>
        <InnerFooterWrapper>
          <Link to={"/"} onClick={()=>window.scrollTo(0,0)}>
            <LogoWrapper>
              <Logo
                src={'assets/camacgroup/logoWhite.png'}
                alt={'Logo'}
              />
            </LogoWrapper>
          </Link>
          {/*<AwardWrapper>
            Awards
            <InnerAwardWrapper>
              <A >
                <Award
                />
              </A>
              <A >
                <Award
                />
              </A>
              <A >
                <Award
                />
              </A>
            </InnerAwardWrapper>
          </AwardWrapper>*/}
        </InnerFooterWrapper>
      </InnerWrapper>
    </Wrapper>
  );
}

export default Footer

const LogoWrapper = styled.div`
    margin-top: 80px;
    @media (min-width: 768px) {
        display: block;
        margin-top: 124px;
        margin-right: 48px;
    }
`;

const DetailsWrapper = styled.div`
    min-width: 192px;
    @media (min-width: 768px) {
        margin-top: 12px;    
    }
`;
const Logo = styled.img`
  height: 24px;
  width: 152px;
  object-fit: fill;  
`;
// const A = styled.a``;

const TitleWrapper = styled.h1`
    font-size: 32px;
    font-weight: normal;
    font-family: StudioFeixenSansVariable;
    line-height: 40px;
    letter-spacing: -0.1px;
    @media (min-width: 768px) {
        line-height: 133%;
        letter-spacing: -0.17px;
        font-weight: normal;
        font-size: 3.3vw;
    }
    @media (min-width: 1440px) {
        font-size: 48px;
    }
`;

const MailWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #FFFFFF;
    margin-top: 46px;
    @media (min-width: 768px) {
        margin-top: 124px;
    }
`;

const ContentWrapper = styled.div`
    @media (min-width: 768px) {
        width: 50%;
        // max-width: 500px;
    }
`;

const LocationsWrapper = styled.div`
    @media (min-width: 768px) {
        margin-right: 20px;
        align-content: flex-start;
    }
`;

const InnerBodyWrapper = styled.div`
    @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
        // max-width: 488px;
    }
`;

const InnerFooterWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column-reverse;
   
    @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
`;

const YourMailTextWrapper = styled.input`
    font-size: 24px;
    height: 36px;
    color: #FFFFFF;
    font-weight: normal;
    background-color: transparent;
    border: none;
    outline: none;
    font-family: StudioFeixenSansVariable;
    line-height: 36px;
    letter-spacing: -0.1px;
    @media (min-width: 768px) {
        font-weight: normal;
        font-size: 2.5vw;
        width: 100%;
        line-height: 98%;
        letter-spacing: -0.13px;
    }
     @media (min-width: 1440px) {
        font-size: 32px;
    }
`;

const Label = styled.label`
    width: 90%;
`;

const Button = styled.button`
    border: none;
    outline: none;
    background: #000000;
`;

const ArrowImg = styled.img`
    // font-size: 24px;
    // width: 18px;
    // height: 36px;
    // color: #D8D8D8;
    // font-weight: bold;
    // @media (min-width: 768px) {
    //     font-size: 32px;
    // }
`;

const TextWrapper1 = styled.p`
    font-family: StudioFeixenSansVariable;
    font-size: 16px;
    font-weight: normal;
    margin-top: 21px;
    line-height: 24px;
    letter-spacing: -0.2px;
`;

const PrivacyPolicyWrapper = styled.div`
    font-size: 16px;
    font-weight: normal;
    font-family: StudioFeixenSansVariable;
    margin-top: 48px;
    color: #FFFFFF;
    text-decoration: underline #FFFFFF;
    line-height: 32px;
    letter-spacing: -0.07px;
     @media (min-width: 768px) {
        margin-top: 64px;
    }
`;

const LocationWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 32px;
    @media (min-width: 768px) {
        margin: 20px 0 40px 0;
        flex-direction: column;
        border-top: none;
    }
`;



const Location = styled.p`
    font-family: StudioFeixenSansVariable;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    letter-spacing: -0.3px;
    @media (max-width: 768px) {
        font-size: 16px
    }
    
`;

const TextWrapper2 = styled.p`
  font-family: StudioFeixenSansVariable;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
  @media (max-width: 768px) {
      font-size: 16px
  }
`;


const Wrapper = styled.footer`
  display: flex;
  justify-content: center; 
  width: 100%;
  background: #000000;
  color: #FFFFFF;
  min-height: 624px;
  @media (min-width: 768px) {
     min-height: 624px;
  }
`;

const InnerWrapper = styled.div`
  margin: 84px 32px 10px 32px;
  max-width: 1440px;
  width: 100%;
  @media (min-width: 768px) {
    margin: 55px 32px 88px 32px;
  }
    
`;

// const InnerAwardWrapper = styled.div`
//     display: flex;
//     justify-content: space-between;
//     margin-top: 22px;
//     width: 100%;
//      @media (min-width: 768px) {
//         width: 288px;
//         align-self: flex-end;
//         margin-bottom: 5px;
//     }
// `;

// const AwardWrapper = styled.div`
//   font-weight: bold;
//   font-size: 24px;
//   line-height: 32px;
//   @media (max-width: 768px) {
//     font-size: 16px;
//     line-height: 24px;
//     margin-top: 47px;
//   }
// `;

// const Award = styled.div`
//   width: 80px;
//   height: 80px;
//   border-radius: 50%;
//   background: #D8D8D8;
// `;

