// nodemodule imports
import styled from 'styled-components';

// project imports


export const Title = styled.div`
  font-family: StudioFeixenSansVariable;
  font-style: normal;
  font-weight: ${props => props.theme.fontWeight};
  margin: ${props => props.theme.margin};
  width: ${props => props.theme.width};
  text-align: ${props => props.theme.textAlign};
  color: ${props => props.theme.color};
  font-size: 80px;
  line-height: 80px;
  letter-spacing: -0.333333px;
  @media (max-width: 900px) {
    font-size: 40px;
    line-height: 45px;
    text-align: center;
  }
`

Title.defaultProps = {
  theme: {
    textAlign: 'left',
    fontWeight: 'normal',
    margin: '',
    width: '',
    color: '',
  }
}

export const TextH2 = styled.div`
  font-family: GTAlpinaTrial;
  font-style: normal;
  font-weight: ${props => props.theme.fontWeight};
  margin: ${props => props.theme.margin};
  width: ${props => props.theme.width};
  text-align: ${props => props.theme.textAlign};
  color: ${props => props.theme.color};
  font-size: 56px;
  max-width: ${props => props.theme.maxWidth};
  line-height: 67px;
  letter-spacing: -0.233333px;
  @media (max-width: 900px) {
    font-size: 32px;
    line-height: 32px;
  }
`

TextH2.defaultProps = {
  theme: {
    textAlign: 'left',
    fontWeight: 'normal',
    margin: '',
    width: '',
    maxWidth: '',
    color: '',
  }
}


export const TextH4 = styled.div`
  font-family: StudioFeixenSansVariable;
  font-style: normal;
  font-weight: ${props => props.theme.fontWeight};
  margin: ${props => props.theme.margin};
  width: ${props => props.theme.width};
  text-align: ${props => props.theme.textAlign};
  color: ${props => props.theme.color};
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.133333px;
  cursor: pointer;
`

TextH4.defaultProps = {
  theme: {
    textAlign: 'left',
    fontWeight: 'normal',
    margin: '',
    width: '',
    color: ''
  }
}

export const TextP1 = styled.div`
  font-family: StudioFeixenSansVariable;
  font-style: normal;
  font-weight: ${props => props.theme.fontWeight};
  margin: ${props => props.theme.margin};
  width: ${props => props.theme.width};
  max-width: ${props => props.theme.maxWidth};
  text-align: ${props => props.theme.textAlign};
  color: ${props => props.theme.color};
  font-size: 24px;
  line-height: ${props => props.theme.lineHeight};
  letter-spacing: -0.1px;
  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 24px;
  }
`

TextP1.defaultProps = {
  theme: {
    textAlign: 'left',
    fontWeight: 'regular',
    margin: '',
    width: '',
    maxWidth: '',
    lineHeight: "28px",
    color: ''
  }
}

export const TextP2 = styled.div`
  font-family: StudioFeixenSansVariable;
  font-style: normal;
  font-weight: ${props => props.theme.fontWeight};
  margin: ${props => props.theme.margin};
  width: ${props => props.theme.width};
  text-align: ${props => props.theme.textAlign};
  color: ${props => props.theme.color};
  font-size: 16px;
  line-height: ${props => props.theme.lineHeight};
  letter-spacing: -0.0666667px;
`

TextP2.defaultProps = {
  theme: {
    textAlign: 'left',
    fontWeight: 'normal',
    margin: '',
    width: '',
    lineHeight: "24px",
    color: ''
  }
}

export const Button = styled.div`
  font-family: StudioFeixenSansVariable;
  font-style: normal;
  width: ${props => props.theme.width};
  position: ${props => props.theme.position};
  top: ${props => props.theme.top};
  left: ${props => props.theme.left};
  right: ${props => props.theme.right};
  bottom: ${props => props.theme.bottom};
  z-index: ${props => props.theme.zIndex};
  height: ${props => props.theme.height};
  border: 1px solid #000000;
  color: #000000;
  font-size: 24px;
  line-height: 28px;
  font-weight: ${props => props.theme.fontWeight};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.1px;
  margin: ${props => props.theme.margin};
  cursor: pointer;
  :hover {${props => props.theme.hover}}
  @media (max-width: 500px) {
    width: 90px;
    height: 30px;
    font-size:16px;
  }  
`

Button.defaultProps = {
  theme: {
    fontWeight: '',
    width: "208px",
    height: "64px",
    margin: '',
    hover: '',
    position: '',
    top: '',
    bottom: '',
    left: '',
    right: '',
    zIndex: ''
  }
}

export const Button2 = styled.div`
  font-family: StudioFeixenSansVariable;
  font-style: normal;
  width: ${props => props.theme.width};
  position: ${props => props.theme.position};
  top: ${props => props.theme.top};
  left: ${props => props.theme.left};
  right: ${props => props.theme.right};
  bottom: ${props => props.theme.bottom};
  z-index: ${props => props.theme.zIndex};
  height: ${props => props.theme.height};
  transition: ${props => props.theme.transition};
  border: 1px solid #000000;
  color: #000000;
  font-size: 24px;
  line-height: 28px;
  font-weight: ${props => props.theme.fontWeight};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.1px;
  margin: ${props => props.theme.margin};
  cursor: pointer;
  :hover {${props => props.theme.hover}}
`

Button2.defaultProps = {
  theme: {
    fontWeight: '',
    width: "208px",
    height: "64px",
    margin: '',
    hover: '',
    position: '',
    top: '',
    bottom: '',
    left: '',
    right: '',
    zIndex: '',
    transition: ''
  }
}

export const Line = styled.div`
  width: 100%;
  height: 2px;
  background: #000;
  margin: ${props => props.theme.margin};
`

Line.defaultProps = {
  theme: {
    margin: '',
  }
}
