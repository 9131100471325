// nodemodule imports
import React from "react";
import styled from "styled-components";
import ScrollAnimation from 'react-animate-on-scroll'

// project imports


export default ({ reversed, imgSrc, imgAlt, title, text, white }) => {
  return (
    <Wrapper>
      <ScrollAnimation animateOnce={true} animateIn={"myAnimation"}>
      <Flex reversed={reversed}>
        <First>
          <Img src={imgSrc} alt={imgAlt} />
        </First>
        <Second>
          <Title white={white}>{title}</Title>
          <Text white={white} theme={TextP1theme}>{text}</Text>
        </Second>
      </Flex>
      </ScrollAnimation>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  margin: 160px auto;
  @media (max-width: 700px) {
    margin: 60px auto;
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: ${props => props.reversed ? "row-reverse" : "row"};
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`

const First = styled.div`
  width: calc(50% - 25px);
  @media (max-width: 700px) {
    width: calc(100%);
    margin-bottom:20px;
  }
`

const Second = styled.div`
  width: calc(50% - 25px);
  @media (max-width: 700px) {
    width: calc(100%);
  }
`

const Title = styled.h3`
  font-family: GTAlpinaTrial; 
  font-size: 56px;
  color: ${props => props.white ? "#000" : "#fff"};
  font-weight:600;
  padding-bottom:24px;
  @media (max-width: 600px) {
    font-size: 32px;
  }
`

const Text = styled.p`
  font-size: 24px;
  color: ${props => props.white ? "#000" : "#fff"};
  font-weight:500;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`

const Img = styled.img`
  width: 100%;
`

const TextP1theme = {
  margin: '22px 0 0 0',
  lineHeight: "32px",
}