// nodemodule imports
import React from "react";
import styled from "styled-components";

// project imports


export default () => {
  return (
    <Wrapper>
      <Flex>
        <First>
          <Title>Smart investment</Title>
          <Text>CAMAC Group is disrupting the traditional industries with an interdisciplinary approach, one company with big goals for the whole world. We're inviting you to be part of our future.</Text>
          <form action="/contact-thank-you" name="contact" method="post" >
            <input type="hidden" name="form-name" value="contact" />
            <InputWrapper>
              <InformationInput
                type={'text'}
                name={'firstname'}
                placeholder={'Full name'}
              />
              <InformationInput
                type={'text'}
                name={'surname'}
                placeholder={'Company name'}
              />
              <InformationInput
                type={'email'}
                name={'emailAddress'}
                placeholder={'Email address'}
              />
            </InputWrapper>
            <SubmitButton type="submit">Send</SubmitButton>
          </form>
        </First>
        <Second>
          <Img 
            src="../../assets/camacgroup/img10.png" 
            alt="camacgroup img 10" 
          />
        </Second>
      </Flex>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  @media (max-width: 700px) {
    margin: 100px auto;
  }
`

const Flex = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`

const First = styled.div`
  width: calc(50% - 25px);
  @media (max-width: 700px) {
    width: calc(100%);
    margin-bottom:20px;
  }
`

const Second = styled.div`
  width: calc(50% - 25px);
  @media (max-width: 700px) {
    width: calc(100%);
  }
`

const Title = styled.h3`
  font-size: 56px;
  color: #000;
  font-weight:500;
  padding-bottom:24px;
  @media (max-width: 600px) {
    font-size: 32px;
  }
`

const Text = styled.p`
  font-size: 24px;
  color: "#000";
  font-weight:500;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`

const Img = styled.img`
  width: 100%;
`

const InformationInput = styled.input`
  width: 100%;
  max-width:500px;
  border:0;
  border-bottom:2px solid #000;
  color:#000000;
  font-size: 24px;
  margin-top: 24px;
  background: transparent;
  font-family: StudioFeixenSansVariable;
  padding:0;
  outline: none;
  font-weight: normal;
  line-height: 24px;
  margin-top:48px;
  padding-bottom:12px;
  border-radius:0;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: 28px;
  @media (min-width: 900px) {
      
  }
`;

const SubmitButton = styled.button`
  width: 208px;
  height: 64px;
  font-size: 24px;
  margin-top: 64px;
  background: transparent;
  font-family: GTAlpinaTrial;
  border: 2px solid #000000;
  outline: none;
`;