import Switch from 'react-router-dom/Switch';
import Route from 'react-router-dom/Route';
import React from 'react';

import Home from './pages/home';


const Routes = () => {

  return (
    <Switch >
      <Route exact path="/" component={Home}/>
      <Route exact path="/*" component={Home}/>
    </Switch>
  );
 }

 export default Routes;
